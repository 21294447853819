module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"loading":"lazy","showCaptions":true,"disableBgImage":true,"withWebp":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Twinn Support","language":"en","description":"Twinn support and FAQ - with elastic search","canonical":"https://www.support.twinn.me/","openGraph":{"type":"website","locale":"en_US","url":"https://www.support.twinn.me/","description":"Twinn support and FAQ - with elastic search","title":"Twinn Support","site_name":"Twinn Support"},"twitter":{"site":"@FazakerleySam","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Twinn Support","short_name":"Twinn Support","start_url":"/","background_color":"#fbfbfb","theme_color":"#fbfbfb","display":"minimal-ui","icon":"static/assets/icon500.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d2b1ae7c20e14636fda294f23a63973"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
